import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./CustomToast.module.scss";
import { useTelegram } from "../../../hooks/Layout/useTelegramHook";
import cx from "classnames";

const SuccessIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="#4BB543">
    <path d="M12 0C5.383 0 0 5.383 0 12c0 6.617 5.383 12 12 12 6.617 0 12-5.383 12-12C24 5.383 18.617 0 12 0zm-1.2 17.4l-5.4-5.4 1.68-1.68L10.8 13.92l6.72-6.72L19.2 9l-8.4 8.4z" />
  </svg>
);

const ErrorIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="#FF3E3E">
    <path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0zm5.656 16.97L16.97 17.656 12 12.686l-4.97 4.97-0.686-0.686L11.314 12 6.343 7.03l0.686-0.686L12 11.314l4.97-4.97 0.686 0.686L12.686 12l4.97 4.97z" />
  </svg>
);

const CustomToast = () => {
  const { tg } = useTelegram();
  const isAndroid = tg.platform === "android";
  return (
    <ToastContainer
      autoClose={3000}
      closeOnClick
      draggable
      className={cx(styles.toastContainer, { [styles.android]: isAndroid })}
      toastClassName={styles.toast}
      bodyClassName={styles.body}
      limit={1}
      draggablePercent={20}
      pauseOnFocusLoss={false}
      pauseOnHover={false}
    />
  );
};

// Генерируем идентификатор для Toast на основе сообщения
const getToastId = (message, eventType) => {
  return eventType ? `${eventType}-${message}` : message;
};

export const showActionToast = (message, action, eventType) => {
  const toastId = getToastId(message, eventType);

  if (!toast.isActive(toastId)) {
    toast(message, {
      icon: <SuccessIcon />,
      onClick: action,
      toastId: toastId,
    });
  } else {
    toast.update(toastId, {
      render: message,
      icon: <SuccessIcon />,
      onClick: action,
      autoClose: 3000,
    });
  }
};

export const showSuccessToast = (message, eventType) => {
  const toastId = getToastId(message, eventType);

  if (!toast.isActive(toastId)) {
    toast.success(message, {
      icon: <SuccessIcon />,
      toastId: toastId,
    });
  } else {
    toast.update(toastId, {
      render: message,
      icon: <SuccessIcon />,
      autoClose: 3000,
    });
  }
};

export const showErrorToast = (message, eventType) => {
  const toastId = getToastId(message, eventType);

  if (!toast.isActive(toastId)) {
    toast.error(message, {
      icon: <ErrorIcon />,
      toastId: toastId,
    });
  } else {
    toast.update(toastId, {
      render: message,
      icon: <ErrorIcon />,
      autoClose: 3000,
    });
  }
};

export default CustomToast;
