import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customBackground: null,
};

const backgroundSlice = createSlice({
  name: "background",
  initialState,
  reducers: {
    setCustomBackground: (state, action) => {
      state.customBackground = action.payload;
    },
    resetCustomBackground: (state) => {
      state.customBackground = null;
    },
  },
});

export const { setCustomBackground, resetCustomBackground } =
  backgroundSlice.actions;

export default backgroundSlice.reducer;
