import { getImageUrl } from "./constants";

export const apiUrl = import.meta.env.VITE_API_URL;
export const socketApiUrl = import.meta.env.VITE_SOCKET_URL;

export const MusicPreload = {
  MUSIC: `https://s3.pathgame.app/public/config/path_1.wav`,
};

export const PreloadImages = {
  NAVBAR_HOME: `${getImageUrl()}navbar/home.webp`,
  NAVBAR_TASKS: `${getImageUrl()}navbar/quests.webp`,
  NAVBAR_RATING: `${getImageUrl()}navbar/rating.webp`,
  NAVBAR_SHOP: `${getImageUrl()}navbar/shop.webp`,
  NAVBAR_HEROES: `${getImageUrl()}navbar/heroes.webp`,

  GRAY_BUTTON: `${getImageUrl()}buttons/button_grey.webp`,
  BLACK_BUTTON: `${getImageUrl()}buttons/button_black.webp`,
  FANCY_BUTTON: `${getImageUrl()}buttons/button_fancy.webp`,

  COIN_ICON: `${getImageUrl()}coins/pathcoin.webp`,
  WALLET_ICON: `${getImageUrl()}icons/wallet.webp`,

  PROFILE_DARK: `${getImageUrl()}profiles/profile_dark.webp`,
  PROFILE_LIGHT: `${getImageUrl()}profiles/profile_white.webp`,

  REFERRAL_ICON: `${getImageUrl()}icons/referral.webp`,
  MUSIC_ICON_ON: `${getImageUrl()}icons/music_on.webp`,
  MUSIC_ICON_OFF: `${getImageUrl()}icons/music_off.webp`,

  BACKGROUND_IMAGE: `${getImageUrl()}icons/game_layout.webp`,

  GUIDER: `${getImageUrl()}onboarding/guider.webp`,

  QUEST_ICON: `${getImageUrl()}icons/quests_icon.webp`,
  TASKS_ICON: `${getImageUrl()}icons/tasks_icon.webp`,
  SHOP_ICON: `${getImageUrl()}icons/shop_icon.webp`,
  EVENTS_ICON: `${getImageUrl()}icons/events_icon.webp`,
  INVENTORY_ICON: `${getImageUrl()}icons/inventory.webp`,
  GIVEAWAYS_ICON: `${getImageUrl()}icons/giveaways.webp`,
  DUNGEON_ICON: `${getImageUrl()}icons/dungeon.webp`,
  SETTINGS_ICON: `${getImageUrl()}icons/settings.webp`,
  SUPPORT_ICON: `${getImageUrl()}icons/support_icon.webp`,
  // HAT_ICON: `${getImageUrl()}icons/hat.webp`,
  TRANSACTIONS_HISTORY_ICON: `${getImageUrl()}icons/transactions_history.webp`,
  CAULDRON_ICON: `${getImageUrl()}icons/cauldron.webp`,
  CASTLE_ICON: `${getImageUrl()}castle.webp`,
  PERGAMENT: `${getImageUrl()}pergament.jpg`,
  QUESTS_BANNER: `${getImageUrl()}quests_banner.webp`,
  GIVEAWAY_BANNER: `${getImageUrl()}giveaway_banner.webp`,
};
