export function useTelegram() {
  const tg = window.Telegram.WebApp;

  const redirect = (url) => {
    if (!url) return;

    if (url.includes("t.me")) {
      tg.openTelegramLink(url);
    } else {
      tg.openLink(url);
    }
  };

  return {
    tg,
    redirect,
  };
}
