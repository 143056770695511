import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  queueState: null,
  isLoading: false,
  error: null,
};

const matchmakingSlice = createSlice({
  name: "matchmaking",
  initialState,
  reducers: {
    setQueueState: (state, action) => {
      state.queueState = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const { setQueueState, setLoading, setError } = matchmakingSlice.actions;

export default matchmakingSlice.reducer;
