import { ApolloClient, InMemoryCache, split, HttpLink } from "@apollo/client";
import { Socket as PhoenixSocket } from "phoenix";
import * as AbsintheSocket from "@absinthe/socket";
import { createAbsintheSocketLink } from "@absinthe/socket-apollo-link";
import { hasSubscription } from "@jumpn/utils-graphql";

const getAuthorizationToken = () => {
  if (window?.Telegram?.WebApp?.initData) {
    return window.Telegram.WebApp.initData;
  }

  const storedData = localStorage.getItem("parsedData");
  if (storedData) {
    const parsedData = JSON.parse(storedData);
    return parsedData.initData || "";
  }
  return "";
};

// Create the Phoenix Socket
const phoenixSocket = new PhoenixSocket(
  `${import.meta.env.VITE_SOCKET_URL.replace("http", "ws")}/socket`,
  {
    params: () => ({
      init_data: getAuthorizationToken(),
    }),
  }
);

// Create Absinthe Socket and WebSocket Link
const absintheSocket = AbsintheSocket.create(phoenixSocket);
const websocketLink = createAbsintheSocketLink(absintheSocket);

// HTTP Link for queries and mutations
const httpLink = new HttpLink({
  uri: `${import.meta.env.VITE_SOCKET_URL}/api/graphql`,
  headers: {
    Authorization: `Bearer ${getAuthorizationToken()}`,
  },
});

// Split traffic based on operation type
const splitLink = split(
  (operation) => hasSubscription(operation.query),
  websocketLink,
  httpLink
);

export const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});
