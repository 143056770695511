import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setTelegramData, setUserData } from "../../store/slices/telegramSlice";
import i18n from "../../utils/i18n";

const parseTelegramData = (base64String) => {
  try {
    const jsonString = atob(base64String);
    return JSON.parse(jsonString);
  } catch (error) {
    return null;
  }
};

export const useInitApp = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const initializeApp = () => {
      let parsedData;

      if (
        window.Telegram &&
        window.Telegram.WebApp &&
        window.Telegram.WebApp.initData
      ) {
        const tg = window.Telegram.WebApp;
        const params = new URLSearchParams(tg.initData);
        const user = JSON.parse(decodeURIComponent(params.get("user")));

        parsedData = {
          initData: tg.initData,
          user,
          platform: tg.platform,
        };
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const telegramDataParam = urlParams.get("telegramData");

        parsedData = parseTelegramData(telegramDataParam);
      }

      if (parsedData && parsedData.user?.id) {
        localStorage.setItem("parsedData", JSON.stringify(parsedData));
        dispatch(setTelegramData(parsedData));
        dispatch(setUserData(parsedData.user));

        const userLanguage = parsedData.user.language_code || "en";
        i18n.changeLanguage(userLanguage);
        setIsInitialized(true);
      } else {
        window.location.href = import.meta.env.VITE_BOT_URL;
      }
    };

    initializeApp();
  }, [dispatch]);

  return { isInitialized };
};
