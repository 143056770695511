import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useAudioHook from "./useAudioHook";
import usePreloadImages from "./usePreloadImages";
import { PreloadImages } from "../../utils/preloadImages";

export const useApp = () => {
  const [loadingComplete, setLoadingComplete] = useState(false);

  const telegramData = useSelector((state) => state.telegram.telegramData);
  const user = telegramData?.user;
  const photoUrl = user?.photo_url;
  const isGame = useSelector((state) => state.telegram.isGame);

  const progress = usePreloadImages({ ...PreloadImages, photoUrl });
  useAudioHook({ loadingComplete });

  useEffect(() => {
    if (progress === 100) {
      setLoadingComplete(true);
    }
  }, [progress]);

  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      window?.telegramAnalytics?.init({
        token: import.meta.env.VITE_ANALYTICS_TOKEN,
        appName: "ThePath",
      });
    }
  }, []);

  return {
    loadingComplete,
    isGame,
    telegramData,
    user,
  };
};
