import { ApolloProvider } from "@apollo/client";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import "./utils/i18n";
import { store } from "./store/store";
import { client } from "./services/apolloClient";
import { Buffer } from "buffer";
import { initSentry } from "./utils/sentryInit";
import loadAmplitude from "./utils/amplitude-loader";
import App from "./App";

window.Buffer = Buffer;

initSentry();

loadAmplitude();

if (process.env.NODE_ENV !== "production") {
  loadDevMessages();
  loadErrorMessages();
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <App />
    </Provider>
  </ApolloProvider>
);
