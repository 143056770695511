import { configureStore } from "@reduxjs/toolkit";

import backgroundReducer from "./slices/backgroundSlice";
import guideReducer from "./slices/guideSlice";
import loaderReducer from "./slices/loaderSlice";
import matchmakingReducer from "./slices/matchmakingSlice";
import socketReducer from "./slices/socketSlice";
import telegramReducer from "./slices/telegramSlice";

export const store = configureStore({
  reducer: {
    telegram: telegramReducer,
    loader: loaderReducer,
    socket: socketReducer,
    guide: guideReducer,
    background: backgroundReducer,
    matchmaking: matchmakingReducer,
  },
});
