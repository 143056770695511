const loadAmplitude = () => {
  if (import.meta.env.VITE_RELEASE_LEVEL === "development") {
    console.log("Amplitude analytics not loaded in development mode");
    return;
  }

  const amplitudeScript = document.createElement("script");
  amplitudeScript.src =
    "https://cdn.amplitude.com/libs/analytics-browser-2.11.1-min.js.gz";
  amplitudeScript.crossOrigin = "anonymous";
  document.head.appendChild(amplitudeScript);

  const sessionReplayScript = document.createElement("script");
  sessionReplayScript.src =
    "https://cdn.amplitude.com/libs/plugin-session-replay-browser-1.8.0-min.js.gz";
  sessionReplayScript.crossOrigin = "anonymous";
  document.head.appendChild(sessionReplayScript);

  amplitudeScript.onload = () => {
    sessionReplayScript.onload = () => {
      if (window.amplitude && window.sessionReplay) {
        window.amplitude.add(window.sessionReplay.plugin({ sampleRate: 1 }));
        window.amplitude.init("f9eaa9ddefa9edd14f6bec50bbf646db", {
          autocapture: { elementInteractions: true },
        });
        console.log("Amplitude analytics initialized");
      }
    };
  };
};

export default loadAmplitude;
