import React, { lazy } from "react";
import CoinLoader from "./components/CoinLoader/CoinLoader";
import { SocketProvider } from "./utils/socketContext";
import { useApp } from "./hooks/Layout/useAppHook";
import { useInitApp } from "./hooks/Layout/useInitAppHook";
import CustomToast from "./components/ui/Toast/Toast";

const Game = lazy(() => import("./pages/Game"));
const Registration = lazy(() => import("./pages/Registration/Registration"));

const App = () => {
  const { isInitialized } = useInitApp();
  const { loadingComplete, isGame, telegramData, user } = useApp();

  if (!isInitialized) {
    return null;
  }

  if (!loadingComplete) {
    return <CoinLoader />;
  }

  return (
    <SocketProvider telegramData={telegramData} user={user}>
      {!isGame && <Registration />}
      {isGame && <Game />}
      <CustomToast />
    </SocketProvider>
  );
};

export default App;
